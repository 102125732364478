@import "variables";

body {
    [data-toggle=intro] {
        display: none!important;
    }
}

body#intro {
    .top-border,
    .top-bar,
    [data-toggle=selection],
    [data-toggle=analysis] {
        display: none !important;
    }

    [data-toggle=intro] {
        display: block !important;
    }

    .bottom-bar>div {
        z-index:999;
    }
}