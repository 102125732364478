@import "variables";

.col-right {
    tr.even {
        background: #e6e6e6 !important;

        &:hover {
            background: #f9f9f9 !important;
        }
    }

    tr.odd {
        background: #ececec !important;

        &:hover {
            background: #f9f9f9 !important;
        }
    }
}

.checkbox-label {
    &:hover input.disabled ~ .checkmark {
        border: none;
    }

    input.disabled ~ .checkmark {
        cursor: default;
        opacity: 0.4;
    }
}