@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

* {outline: 0 !important;}

body {background-image: linear-gradient(to bottom, #e2001a 0px, #e2001a 5px, #fff 5px, #fff 97px, #f4f4f4 97px, #f4f4f4 100%); background-repeat: no-repeat; background-color: #f4f4f4; font-family: 'Ubuntu', sans-serif;}
body#intro {min-height: 100vh; margin: 0; background-color: #eee; background-image: url('../../img/intro-bg.jpg'), linear-gradient(to bottom, #e2001a 0px, #e2001a 5px, transparent 5px, transparent 100%); background-repeat: no-repeat, no-repeat; background-size: 1920px auto, 100% 100%; background-attachment: fixed, fixed; background-position: center 5px, center top;}
body#intro .bottom-bar > div {z-index: 999;}

.container-fluid {max-width: 2560px; padding: 0;}

.hide {display: none !important;}

/* intro page */

#intro div[data-toggle="intro"] {min-height: calc(100vh - 40px); margin: 0; display: flex; flex-direction: column;}

#intro .intro-content {flex: 1; max-width: 1920px; min-height: 1000px; position: relative;}

#intro-logos {padding: 100px 0 60px 155px;}
#intro-logos img:first-child {margin-right: 60px;}

#intro-text {position: relative; z-index: 10; width: 400px; margin: 0 0 0 145px;}
#intro-text h1 {margin: 0 0 55px 0; font-size: 118px; line-height: 0.85; font-weight: 600; text-transform: uppercase;}
#intro-text p {margin: 0 0 50px 10px; font-size: 16px;}
#intro-text a.button {margin: 0 0 60px 10px;}

#intro-robots {z-index: 10; position: absolute; top: 125px; left: 445px; width: 1090px; /* height: 1058px; */ transform: scale(0.90); border: 0px solid #f00;}
#intro-robot-1 {position: absolute; top: 265px; left: 0; animation-name: fadeIn; animation-duration: 1.5s; animation-timing-function: ease; animation-fill-mode: both; animation-delay: .5s;}
#intro-robot-2 {position: absolute; top: 0; right: 0; animation-name: fadeIn; animation-duration: 1.5s; animation-timing-function: ease; animation-fill-mode: both; animation-delay: 1s;}

#intro-red-1 {z-index: 5; position: fixed; top: -800px; right: -1100px; animation-name: rotate1; animation-duration: 1s; animation-timing-function: ease-in-out; animation-fill-mode: both; animation-delay: 0.5s;}
#intro-red-2 {z-index: 5; position: fixed; left: -1200px; bottom: -1040px; animation-name: rotate2; animation-duration: 1s; animation-timing-function: ease-in-out; animation-fill-mode: both;}

@keyframes rotate1 {
from {transform: rotate(-15deg); opacity: 0;}
to {transform: rotate(0deg); opacity: .5;}
}
@keyframes rotate2 {
from {transform: rotate(15deg); opacity: 0;}
to {transform: rotate(0deg); opacity: .5;}
}
@keyframes fadeIn { from {opacity: 0;} to {opacity: 1;} }

@media (max-width: 1920.98px) {
body#intro {background-position: 0 5px, center top;}
}
@media (max-width: 1699.98px) {
body#intro {background-size: 1700px auto, 100% 100%; background-position: 110px 5px, center top; }
#intro .intro-content {min-height: 940px;}
#intro-robots {transform: scale(0.85); top: 120px;}
#intro-text a.button {margin: 0 0 80px 10px;}
#intro-red-1 {left: 860px;}
#intro-red-2 {bottom: -900px; left: -1000px;}
#intro-red-1 img, #intro-red-2 img {transform: scale(0.75);}
}
@media (max-width: 1499.98px) {
body#intro {background-size: 1500px auto, 100% 100%; background-position: 0 5px, center top;}
#intro .intro-content {min-height: 800px;}
#intro-logos {padding: 85px 0 60px 95px;}
#intro-text {width: 360px; margin: 0 0 0 85px;}
#intro-text h1 {font-size: 100px;}
#intro-robots {transform: scale(0.7); top: 90px; left: 255px;}
#intro-red-1 {left: 550px;}
#intro-red-2 {bottom: -850px; left: -1050px;}
#intro-red-1 img, #intro-red-2 img {transform: scale(0.65);}
}
@media (max-width: 1199.98px) {
body#intro {background-size: 1200px auto, 100% 100%; background-position: 50px 30px, center top;}
#intro div[data-toggle="intro"] {min-height: calc(100vh - 60px);}
#intro .intro-content {min-height: 600px;}
#intro-logos {padding: 65px 0 60px 85px;}
#intro-text {width: 300px; margin: 0 0 0 75px;}
#intro-text h1 {font-size: 80px;}
#intro-text p {font-size: 15px;}
#intro-robots {transform: scale(0.62); top: 95px; left: 140px;}
#intro-text a.button {margin: 0 0 40px 10px;}
#intro-red-1 {left: 350px;}
#intro-red-2 {bottom: -890px; left: -1100px;}
}
@media (max-width: 991.98px) {
body#intro {background-size: 992px auto, 100% 100%; background-position: 30px 60px, center top;}
#intro .intro-content {overflow: hidden;}
#intro-logos {padding: 45px 0 60px 50px;}
#intro-text {width: 250px; margin: 0 0 0 45px;}
#intro-text h1 {margin: 0 0 45px 0; font-size: 65px;}
#intro-text p {margin: 0 0 40px 5px; font-size: 14px;}
#intro-robots {transform: scale(0.55); top: 100px; left: 30px;}
#intro-text a.button {margin: 0 0 40px 5px;}
#intro-red-1 {left: 220px;}
#intro-red-1 img, #intro-red-2 img {transform: scale(0.6);}
}
@media (max-width: 849.98px) {
body#intro {background-position: -10px 60px, center top;}
#intro-text {width: 235px;}
#intro-text h1 {font-size: 60px;}
#intro-text p {font-size: 12px;}
#intro-robots {transform: scale(0.5); left: -20px;}
#intro-red-1 {left: 50px;}
#intro-red-2 {bottom: -870px; left: -1000px;}
#intro-red-1 img, #intro-red-2 img {transform: scale(0.5);}
}
@media (max-width: 767.98px) {
body#intro {background-size: 150vw auto, 100% 100%; background-position: -30vw 27%, center top;}
#intro-logos {padding: 45px 10vw 45px 10vw;}
#intro-logos img {width: 33vw; height: auto;}
#intro-logos img:first-child {margin-right: 10vw;}
#intro-text {margin: -40px 0 0 0; padding: 0 10vw; width: auto;}
#intro-text h1 {font-size: 8vw;}
#intro-text p {font-size: 18px;}
#intro-robots {position: relative; top: 0; left: 0; transform: scale(1); width: 100%; height: 100vw; overflow: hidden; }
#intro-robot-1 {z-index: 15; top: 25vw;}
#intro-robot-2 {z-index: 15;}
#intro-robot-1 img {width: 47vw; height: auto;}
#intro-robot-2 img {width: 65vw; height: auto;}
#intro-red-1 {display: none;}
#intro-red-2 {z-index: 10; bottom: -500px; left: auto; right: 40vw;}
#intro-red-2 img {transform: scale(1); width: 1000px; height: auto;}
}
@media (max-width: 575.98px) {
#intro-text h1 {font-size: 18vw;}
#intro-red-2 {right: 25vw;}
}
@media (max-width: 399.98px) {
#intro-text p {font-size: 14px;}
#intro-red-2 {right: 20vw;}
}
 
.top-bar {width: 100%; height: 92px; margin-top: 5px; padding: 0 50px;}
.logo img {height: 55px; margin-right: 60px;}
@media (max-width: 1199.98px) {
.logo img {height: 50px; margin-right: 50px;}
}
@media (max-width: 767.98px) {
.logo img {height: 45px; margin-right: 30px;}
}
@media (max-width: 575.98px) {
.top-bar {padding: 0 25px 0 30px;}
.logo img {height: 40px; margin-right: 25px;}
}
@media (max-width: 399.98px) {
.logo img {height: 35px;}
}
@media (max-width: 359.98px) {
.top-bar {padding: 0 20px 0 25px;}
.logo img {height: 33px; margin-right: 20px;}
}

.user-avatar {width: 38px; height: 38px; font-weight: 300; border: 1px solid #c3c3c3; border-radius: 50%; background-color: #fcfcfc;}
.user-name {margin-left: 15px; font-size: 14px; font-weight: 500;}
.user-settings {margin-left: 82px; margin-right: 28px;}
.user-logout {margin-right: 0;}
.user-options a {font-size: 14px; font-weight: 500; text-decoration: none; color: #000;}
.user-options a:hover {color: #e2001a;}
.user-options span {margin: 0 0 0 10px;}
.user-settings img, .user-logout img {margin-top: -5px;}
@media (max-width: 1920.98px) {
.user-name {font-size: 13px;}
.user-options a {font-size: 13px;}
}
@media (max-width: 991.98px) {
.user-settings {margin-left: 40px;}
.user-options span {display: none;}
}
@media (max-width: 575.98px) {
.user-settings {margin-left: auto; margin-right: 20px;}
}
@media (max-width: 399.98px) {
.user-options img {transform: scale(0.8);}
.user-settings {margin-right: 15px;}
}

/* robot selection */

.header-row {margin-left: 35px; margin-right: 35px; padding-top: 40px;}
.main-row {margin-left: 35px; margin-right: 35px;}
@media (max-width: 575.98px) {
.header-row, .main-row {margin-left: 15px; margin-right: 15px;}
}
@media (max-width: 359.98px) {
.header-row, .main-row {margin-left: 10px; margin-right: 10px;}
}

@media (min-width: 1200px) {
.col-left {-ms-flex: 0 0 35%; flex: 0 0 35%; max-width: 35%; border: 0px solid #f00;}
.col-right {-ms-flex: 0 0 65%; flex: 0 0 65%; max-width: 65%; border: 0px solid #00f;}
}
@media (min-width: 1500px) {
.col-left {-ms-flex: 0 0 40%; flex: 0 0 40%; max-width: 40%; border: 0px solid #f00;}
.col-right {-ms-flex: 0 0 60%; flex: 0 0 60%; max-width: 60%; border: 0px solid #00f;}
}

@media (max-width: 1199.98px) {
.col-right {margin-top: 20px;}
}


.button {cursor: pointer; display: inline-block; padding: 13px 40px; font-size: 14px; line-height: 13px; font-weight: 500; text-transform: uppercase; text-decoration: none; white-space: nowrap; color: #006a76; border: 1px solid #006c78; border-radius: 20px; box-shadow: 5px 5px 20px 0px #e8e8e8; background-size: 100% 200%; background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 50%, #004767 50%, #006c78 100%); transition: all .3s;}
.button-reset {cursor: pointer; display: inline-block; padding: 13px 40px; font-size: 14px; line-height: 13px; font-weight: 500; text-transform: uppercase; text-decoration: none; white-space: nowrap; color: #868889; border-radius: 20px; box-shadow: 5px 5px 20px 0px #e8e8e8; background-size: 100% 200%; background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 50%, #939393 50%, #b2b2b2 100%); transition: all .3s;}
.button:hover {text-decoration: none; background-position: 0 100%; color: #fff;}
.button:active {color: #fff; background-image: linear-gradient(to bottom, #006c78 0%, #004767 100%);}
.button-reset:hover {text-decoration: none; background-position: 0 100%; color: #fff;}
.button-reset:active, .button-reset:focus {background-image: linear-gradient(to bottom, #b2b2b2 0%, #939393 100%);}
.button span {position: relative; padding-left: 25px;}
.button span:before {content: "+"; position: absolute; top: -1px; left: 0; font-size: 20px; font-weight: 700;}
@media (max-width: 1920.98px) {
.button, .button-reset {padding: 12px 40px; font-size: 13px;}
}
@media (max-width: 1499.98px) {
body:not(#intro) .button {padding: 11px 35px;}
.button {font-size: 12px;}
.button-reset {font-size: 12px; padding: 12px 35px;}
}
@media (max-width: 339.98px) {
body:not(#intro) .button {padding: 11px 25px;}
.button-reset {padding: 12px 25px;}
}




/* close button */

.close-button {cursor: pointer; position: relative; display: inline-block; width: 27px; height: 27px; border-radius: 50%; border: 1px solid #e9e9e9; background-color: #fff; box-shadow: 5px 5px 20px 0px #dcdcdc;}
.close-button .bar-1, .close-button .bar-2 {width: 14px; height: 2px; margin: 7px 0 0 3px; background-color: #868889;}
.close-button .bar-1 {transform: rotate(-45deg); transform-origin: 100% 0%;}
.close-button .bar-2 {transform: rotate(45deg); transform-origin: 100% 100%;}


/* toast */

.toast-container {position: fixed; z-index: 1000; padding: 30px; top: 0; right: 0;}
.toast {width: 400px; max-width: 400px; border: none; border-radius: 11px; box-shadow: 0px 0px 15px 0px #e4e4e4;}
.toast-header {padding: 15px 15px 12px 30px; line-height: 1; text-transform: uppercase; border-bottom: 0; border-top-left-radius: 11px; border-top-right-radius: 11px; background-color: #f9f9f9;}
.toast-body {padding: 20px 30px; line-height: 1.25; border-bottom-left-radius: 11px; border-bottom-right-radius: 11px; background-color: #fff;}
.toast-header .close-button {margin-left: 30px;}
@media (max-width: 575.98px) {
.toast {width: 100%; max-width: 100%;}
.toast-header, .toast-body {font-size: 13px;}
}


/* modal */

.modal-open {overflow-y: scroll;}
.modal-backdrop {background-color: transparent;}
.modal-dialog {border-radius: 11px; background-color: transparent;}
.modal-content {padding: 10px 20px 20px 20px; border: none; border-radius: 11px; box-shadow: 0px 0px 10px 0px #d2d2d2;}
.modal-payload .modal-content {background-color: #e6e6e6;}
.modal-bom .modal-content {background: #f4f4f4;}
.modal-header {border-bottom: none; border-top-left-radius: 11px; border-top-right-radius: 11px;}
.modal-footer {border-top: none; border-bottom: none; border-bottom-left-radius: 11px; border-bottom-right-radius: 11px;}
.modal-header h3 {margin: 0; font-size: 16px; text-transform: uppercase; font-weight: 500; color: #1a171b;}
@media (max-width: 1920.98px) {
.modal-header h3 {font-size: 15px;}
}
@media (max-width: 1499.98px) {
.modal-header h3 {font-size: 14px;}
}
@media (max-width: 1399.98px) {
.modal-header h3 {margin: 0; font-size: 13px;}
.modal-dialog.modal-bom {max-width: 1040px;}
}
@media (min-width: 576px) and (max-width: 1199.98px) {
.modal-dialog.modal-bom {max-width: calc(100% - 100px);}
}
@media (min-width: 992px) {
.modal-dialog.modal-payload {max-width: 700px;}
}


/* on-off toggle */

@media (min-width: 950px) {
.switch {display: none;}
}
.switch {position: absolute; top: 20px; right: 24px; width: 50px; height: 23px;}
.switch-enable {position: relative; top: 0; right: 0; display: inline-block !important;}
.switch-inner {position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; border-radius: 23px; background-color: #003961; box-shadow: 0px 5px 20px 0px #cfcfcf; transition: all .4s;}
.switch-inner:before {position: absolute; content: ""; transform: translateX(27px); width: 19px; height: 19px;  left: 2px; bottom: 2px; border-radius: 50%; background-color: white; box-shadow: 3px 3px 15px 0px #9f9f9f; transition: all .4s;}
.switch.collapsed .switch-inner, .switch-enable.off .switch-inner {background-color: #006c78;}
.switch.collapsed .switch-inner:before, .switch-enable.off .switch-inner:before {transform: translateX(0); box-shadow: -3px 3px 15px 0px #9f9f9f;}
.switch-inner-txt:after {position: absolute; top: 4px; left: 8px; content: "ON"; font-size: 10px; font-weight: 500; color: #fff; transition: .4s;}
.switch.collapsed .switch-inner-txt:after, .switch-enable.off .switch-inner-txt:after {top: 4px; left: 24px; content: "OFF";}
.switch-enable {display: inline-block;}
@media (max-width: 1198px) {
.col-left-box-5 .switch {display: block;}
}


/* number input */

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
input[type=number] {-moz-appearance: textfield;}

/* radio buttons */

.radio-label {position: relative; display: inline-block; padding-left: 40px; margin: 14px 0; cursor: pointer; font-size: 14px;}
.radio-label input {position: absolute; opacity: 0; cursor: pointer;}
.radiomark {position: absolute; top: -2px; left: 0; height: 24px; width: 24px; border-radius: 50%; background: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%); box-shadow: 0px 5px 20px 0px #cfcfcf; transition: all .3s;}
.radio-label:hover input ~ .radiomark {border: 1px solid #2c6b76;}
.radio-label input:checked ~ .radiomark {border: 1px solid #2c6b76;}
.radiomark:after {content: ""; position: absolute; display: none; transition: all .3s;}
.radio-label input:checked ~ .radiomark:after {display: block;}
.radio-label .radiomark:after {top: 5px; left: 5px; width: 12px; height: 12px; border-radius: 50%; background: linear-gradient( -45deg, #006c78 0%, #004767 100%);}
@media (max-width: 1920.98px) {
.radio-label {font-size: 13px;}
.radiomark {top: -3px;}
}
@media (max-width: 1499.98px) {
.radio-label {font-size: 12px;}
.radiomark {top: -4px;}
}


/* checkbox buttons */

.checkbox-label {display: inline-block; position: relative; padding-left: 35px; cursor: pointer; font-size: 14px;}
.checkbox-label input {position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0;}
.checkmark {position: absolute; top: -2px; left: 0; height: 25px; width: 25px; border-radius: 7px; background: linear-gradient(to bottom, #fff 0%, #f9f9f9 100%); box-shadow: 5px 5px 20px 0px #cfcfcf; transition: all .3s;}
.checkbox-label:hover input ~ .checkmark {border: 1px solid #2c6b76;}
.checkbox-label:hover input:disabled ~ .checkmark {border: none;}
.checkbox-label input:checked ~ .checkmark {border: 1px solid #2c6b76; background: linear-gradient( -45deg, #006c78 0%, #004767 100%);}
.checkbox-label input:disabled ~ .checkmark {cursor: default; opacity: 0.4;}
.checkmark:after {content: ""; position: absolute; display: none;}
.checkbox-label input:checked ~ .checkmark:after {display: block;}
.checkbox-label .checkmark:after {left: 9px; top: 4px; width: 6px; height: 12px; border: solid white; border-width: 0 2px 2px 0; transform: rotate(45deg);}
@media (max-width: 1920.98px) {
.checkbox-label {font-size: 13px;}
.checkmark {top: -3px;}
}
@media (max-width: 1499.98px) {
.checkbox-label {font-size: 12px;}
.checkmark {top: -4px;}
}


.col-header {margin-bottom: 30px;}
.col-header h1 {margin: 0 0 5px 35px; font-size: 27px; font-weight: 600; text-transform: uppercase; color: #000;}
.col-header p {margin: 0 0 0 35px; font-size: 14px; line-height: 110%; color: #909293;}
.col-left .col-header .button {margin: 3px 0 0 50px;}
@media (max-width: 1920.98px) {
.col-header h1 {font-size: 25px;}
.col-header p {font-size: 13px;}
}
@media (max-width: 1499.98px) {
.col-header h1 {margin: 0 0 5px 25px; font-size: 22px;}
.col-header p {margin: 0 0 0 25px; font-size: 12px;}
.col-left .col-header .button {margin: 3px 0 0 30px;}
}
@media (max-width: 1199.98px) {
.col-header {margin-bottom: 10px;}
.col-right .col-header {margin-bottom: 35px;}
}
@media (max-width: 575.98px) {
.col-right .col-header .analysis-buttons {width: 100%;}
.col-right .col-header .analysis-buttons:first-child {margin-bottom: 15px;}
.col-header p {margin: 0 0 20px 25px;}
.col-left .col-header .button {margin: 0 0 20px 25px;}
}



.col-box {position: relative; -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; margin: 0 0 20px 0; padding: 25px 35px 22px 35px; border-radius: 11px; background-color: #e6e6e6;}
.col-box h3 {margin: 0; font-size: 16px; text-transform: uppercase; font-weight: 500; color: #1a171b;}
.col-box h3 span {text-transform: lowercase;}
.col-box p {margin: 15px 0 0 0; font-size: 12px; line-height: 110%; font-style: italic; color: #909293;}
.col-right-box-1, .col-right-box-2 {padding: 38px 50px 30px 50px;}
@media (max-width: 1920.98px) {
.col-box h3 {font-size: 15px;}
}
@media (min-width: 1699.98px) {
.col-left-box-3 {-ms-flex: 0 0 60%; flex: 0 0 60%; max-width: 60%; margin-right: 20px;}
.col-left-box-4 {-ms-flex: 0 0 40%; flex: 0 0 40%; max-width: calc(40% - 20px);}
}
@media (max-width: 1899.98px) {
.col-right-box-1, .col-right-box-2 {padding: 38px 45px 30px 45px;}
}
@media (max-width: 1599.98px) {
.col-right-box-1, .col-right-box-2 {padding: 33px 40px 30px 40px;}
}
@media (max-width: 1499.98px) {
.col-box h3 {font-size: 14px;}
}
@media (max-width: 1399.98px) {
.col-box {padding: 25px 23px 22px 23px;}
.col-box h3 {margin: 0; font-size: 13px;}
.col-right-box-1, .col-right-box-2 {padding: 30px 35px 25px 35px;}
}
@media (max-width: 1199.98px) {
.col-left-box-1, .col-left-box-3 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: calc(50% - 10px); margin-right: 10px;}
.col-left-box-2, .col-left-box-4 {-ms-flex: 0 0 50%; flex: 0 0 50%; max-width: calc(50% - 10px); margin-left: 10px;}
.col-right-box-1, .col-right-box-2 {padding: 25px 23px 22px 23px;}
}
@media (max-width: 949.98px) {
.col-left-box-1, .col-left-box-2 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; margin-right: 0px; margin-left: 0px;}
.col-left-box-3 {-ms-flex: 0 0 60%; flex: 0 0 60%; max-width: 60%; margin-right: 20px;}
.col-left-box-4 {-ms-flex: 0 0 40%; flex: 0 0 40%; max-width: calc(40% - 20px); margin-left: 0;}
}
@media (max-width: 949.98px) {
.col-left-box-3, .col-left-box-4 {-ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
}
@media (max-width: 349.98px) {
.col-box {padding: 25px 20px 22px 20px;}
}


#box-type {padding: 0 0 0 10px;}
#box-type .robot-type {width: calc(50% - 15px); margin: 30px 0 0 0;}
#box-type .robot-type:nth-child(odd) {margin-right: 15px;}
#box-type .robot-type:nth-child(even) {margin-left: 15px;}
#box-type .robot-type-img {height: 130px;}
#box-type img {height: 130px; width: auto; margin-left: 25px;}
#box-type .robot-type-vertical img {margin-left: 30px;}
#box-type .robot-type-cm img {margin-left: 40px;}
#box-type .checkbox-label {margin: 14px 0;}
@media (max-width: 1920.98px) {
#box-type .robot-type {width: calc(50% - 10px); margin: 30px 0 0 0;}
#box-type .robot-type:nth-child(odd) {margin-right: 10px;}
#box-type .robot-type:nth-child(even) {margin-left: 10px;}
}
@media (max-width: 1819.98px) {
}
@media (max-width: 1699.98px) {
#box-type .robot-type {width: 40%;}
}
@media (max-width: 1499.98px) {
#box-type .robot-type {width: calc(50% - 10px);}
}
@media (min-width: 768px) and (max-width: 949.98px) {
#box-type .robot-type {width: calc(25% - 20px); margin-right: 20px !important; margin-left: 0 !important;}
#box-type .robot-type:last-child {margin-right: 0;}
}
@media (max-width: 449.98px) {
#box-type {padding: 0 0 0 0px;}
#box-type .robot-type-left-col {margin-right: 20px;}
#box-type img, #box-type .robot-type-vertical img {margin-left: 15px;}
}
@media (max-width: 374.98px) {
#box-type .robot-type-img {height: 100px;}
#box-type img {height: 100px;}
}


#box-environment {max-width: 160px; margin-left: 5px;}
#box-environment .radio-label {width: 100%; margin: 14px 0;}
#box-environment .radio-label:first-of-type {margin-top: 55px;}
@media (max-width: 1699.98px) {
#box-environment {max-width: 100%;}
#box-environment .radio-label {width: 32.5%;}
}
@media (max-width: 1499.98px) {
#box-environment .radio-label {width: 48%;}
}
@media (max-width: 949.98px) {
#box-environment .radio-label {width: 32.5%;}
}
@media (max-width: 575.98px) {
#box-environment .radio-label {width: 48%;}
}
@media (max-width: 449.98px) {
#box-environment .radio-label {width: 100%;}
}



#box-payload-data > div {-ms-flex-direction: row; flex-direction: row;}

.palletizing-switch {position: absolute; top: 22px; right: 36px; font-size: 16px;}
.palletizing-switch > span {position: relative; top: -6px; color: #909293;}

.robot-axes-img-container {padding: 20px 50px 0 20px;}
.robot-axes-img-container .robot-axes-img {height: 0; opacity: 0; overflow: hidden; transition: opacity 1s;}
.robot-axes-img-container .robot-axes-img.active {height: auto; opacity: 1;}
.robot-axes-img-container .palletizing-mode-text {margin-top: 30px; color: #909293;}

.robot-axes-data {width: 100%; margin: 35px auto 0 auto;}
.robot-axes-data > div {-ms-flex-direction: column; flex-direction: column;}

.axes-data-container {display: inline-block; width: 185px; height: 36px; margin: 0px 0px 14px 0; text-align: right;}
.axes-data-container.axes-name-container {height: auto;}
.axes-data-container.disabled {opacity: 0.4;}
.axes-data-container label, .axes-data-container span {font-size: 16px; color: #909293;}
.axes-data-input-container {display: inline-block; width: 125px; height: 36px; margin: 0 0 0 10px; padding: 5px 15px; text-align: left; border-radius: 18px; background-color: #fff;}
.axes-data-input-container input {width: 52px; outline: none;}
.axes-name-container .axes-data-input-container {display: inline-block; width: 155px; height: 36px; margin: 0 0 0 10px; padding: 5px 15px; text-align: left; border-radius: 18px; background-color: #fff;}
.axes-name-container .axes-data-input-container input {width: 135px; outline: none;}


.sup-mass-data-container {padding: 0; margin-bottom: 3px; text-align: left;}
.sup-mass-data-container label {margin-bottom: 0; margin-right: 15px;}


#box-payload-data .button {margin: 0 30px 15px 0;}
#box-payload-data .button-reset {margin: 0 0 15px 0;}

@media (max-width: 1920.98px) {
.robot-axes-img-container {padding: 20px 30px 0 10px;}
.axes-data-container {width: 180px;}
.axes-data-input-container {width: 123px;}
.axes-data-input-container input {width: 51px;}
.axes-name-container .axes-data-input-container {width: 150px;}
.axes-name-container .axes-data-input-container input {width: 130px;}
.axes-data-container label, .axes-data-container span, .robot-axes-img-container .palletizing-mode-text {font-size: 15px;}
}
@media (max-width: 1699.98px) {
#box-payload-data > div {-ms-flex-direction: column; flex-direction: column;}
.robot-axes-img-container {padding: 20px;}
#box-payload-data .robot-axes-data > div {-ms-flex-direction: row; flex-direction: row;}
.axes-data-container {width: 170px;}
.axes-data-container label, .axes-data-container span, .robot-axes-img-container .palletizing-mode-text {font-size: 14px;}
.axes-name-container label {margin-right: 8px;}
.axes-data-input-container {width: 110px; padding: 5px 5px 5px 15px;}
}
@media (max-width: 1599.98px) {
.palletizing-switch {top: 20px; right: 24px; font-size: 14px;}
.palletizing-switch .switch {top: 2px;}
.axes-data-container {width: 180px;}
.axes-data-input-container {width: 120px; padding: 5px 15px;}
}
@media (max-width: 1349.98px) {
.palletizing-switch {position: relative; top: auto; right: auto; margin-top: 20px; font-size: 13px;}
.robot-axes-img-container {padding: 20px 15px 0 15px;}
#box-payload-data .axes-data-container {width: 150px;}
.axes-data-input-container {width: 100px; padding: 5px 8px 5px 13px; margin: 0 0 0 8px;}
.axes-data-input-container input {width: 42px;}
}
@media (max-width: 1199.98px) {
#box-payload-data > div {-ms-flex-direction: row; flex-direction: row;}
#box-payload-data .robot-axes-img-container {max-width: 43%;}
#box-payload-data .robot-axes-data-container {padding: 0 0 0 20px;}
}
@media (max-width: 991.98px) {
.modal-payload .modal-body {-ms-flex-direction: column; flex-direction: column;}
.modal-payload .modal-body .robot-axes-data > div {-ms-flex-direction: row; flex-direction: row;}
}
@media (max-width: 767.98px) {
#box-payload-data > div {-ms-flex-direction: column; flex-direction: column;}
#box-payload-data .robot-axes-img-container {max-width: 100%;}
#box-payload-data .robot-axes-data-container {padding: 0;}
}
@media (max-width: 449.98px) {
#box-payload-data .robot-axes-img-container {padding: 20px 0 0 0;}
#box-payload-data .robot-axes-data {width: 100%;}
.axes-data-container:not(.axes-name-container):not(.sup-mass-data-container) {width: 137px !important; height: 32px;}
.axes-data-input-container {width: 95px; height: 32px; padding: 3px 8px 3px 13px; margin: 0 0 0 2px; border-radius: 16px;}
.axes-data-container label, .axes-data-container span, .robot-axes-img-container .palletizing-mode-text {font-size: 13px;}
.axes-name-container label {margin-right: 12px;}
.axes-name-container {margin-left: 12px;}
.axes-data-input-container input {width: 37px; font-size: 14px;}
}
@media (max-width: 379.98px) {
.axes-data-container:not(.axes-name-container):not(.sup-mass-data-container) {width: 127px !important;}
.axes-data-input-container {width: 90px; padding: 3px 3px 3px 10px;}
.axes-data-container.axes-name-container {margin-left: 5px;}
}
@media (max-width: 349.98px) {
#box-payload-data .robot-axes-data {}
.axes-data-container:not(.axes-name-container):not(.sup-mass-data-container) {width: 115px !important;}
.axes-data-container label, .axes-data-container span {font-size: 12px;}
.axes-name-container label {margin-right: 18px;}
.axes-data-input-container {width: 80px;}
.axes-data-input-container input {width: 32px; font-size: 13px;}
}



.add-payloads-container {margin: 50px -35px 0 -35px; border-radius: 11px; background-color: #ececec;}
.add-payloads-container > div {padding: 18px 40px;}
.add-payload-data {border-top: 1px solid #e6e6e6;}
.add-payload-data:last-of-type {border-bottom-right-radius: 11px; border-bottom-left-radius: 11px;}
.add-payload-data:hover {background-color: #f9f9f9;}

.edit-main-payload-data img, .add-payload-buttons img {box-shadow: 5px 5px 20px 0px #dcdcdc;}
.edit-main-payload-data img {margin-right: 62px;}
.add-payload-buttons img {margin-right: 35px;}

.add-payloads-container h3 {margin: 4px 20px 5px 0;}
.add-payloads-container h4 {margin: 5px 0; font-size: 13px; line-height: 110%; font-style: italic; color: #909293;}
.add-payload-title {font-size: 16px; color: #909293;}

@media (max-width: 1920.98px) {
.add-payloads-container h4 {font-size: 12px;}
.add-payload-title {font-size: 15px;}
}
@media (max-width: 1699.98px) {
.add-payload-title {font-size: 14px;}
}
@media (max-width: 1399.98px) {
.add-payloads-container {margin: 50px -23px 0 -23px;}
.add-payloads-container > div {padding: 18px 25px;}
.edit-main-payload-data img {margin-right: 52px;}
.add-payload-buttons img {margin-right: 25px;}
}
@media (max-width: 449.98px) {
.add-payloads-container h4 {font-size: 11px;}
.add-payload-title {font-size: 13px;}
}
@media (max-width: 349.98px) {
.add-payloads-container {margin: 40px -20px 0 -20px;}
.add-payloads-container > div {padding: 18px 20px;}
.add-payload-title {font-size: 12px;}
}



.axes-data-switch {width: 155px; margin-left: auto; box-shadow: 5px 5px 20px 0px #e8e8e8;}
.axes-data-switch-button {width: 50%; display: inline-block; padding: 3px 10px; font-size: 12px; line-height: 13px; font-weight: 500; text-transform: uppercase; text-decoration: none; white-space: nowrap; color: #00355b; border: 1px solid #00355b; background-size: 100% 200%; background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 50%, #00355b 50%, #00355b 100%); transition: all .3s;}
.axes-data-switch-button:nth-child(1) {border-top-left-radius: 13px; border-bottom-left-radius: 13px;}
.axes-data-switch-button:nth-child(2) {border-top-right-radius: 13px; border-bottom-right-radius: 13px;}
.axes-data-switch-button.active, .axes-data-switch-button:hover {text-decoration: none; color: #fff; background-position: 0 100%;}
@media (max-width: 1920.98px) {
.axes-data-switch {width: 150px;}
.axes-data-switch-button {padding: 5px 10px 3px 10px; font-size: 11px;}
}
@media (max-width: 1699.98px) {
.axes-data-switch {width: 140px; margin-left: 30px;}
.axes-data-switch-button {padding: 5px 8px 3px 8px;}
}
@media (max-width: 1599.98px) {
.axes-data-switch {width: 150px;}
.axes-data-switch-button {padding: 5px 12px 3px 12px;}
}
@media (max-width: 1499.98px) {
#box-payload-data .axes-data-switch {margin-left: 60px;}
}
@media (max-width: 1349.98px) {
#box-payload-data .axes-data-switch {margin-left: 50px;}
}
@media (max-width: 449.98px) {
.axes-data-switch {margin-left: 42px !important;}
}
@media (max-width: 379.98px) {
.axes-data-switch {margin-left: 36px !important;}
}



/* slider-range */

.ui-widget-header {background: linear-gradient(to right, #009a8c 0%, #00355f 100%);}

.ui-slider-horizontal {height: 10px;}
.ui-widget.ui-widget-content {border: 0px; border-radius: 5px;}

.ui-slider .ui-slider-handle {top: -10px; width: 30px; height: 30px; margin-left: -15px; outline: none; border: 0; border-radius: 15px; background-color: #f8f8f8; box-shadow: 0px 5px 20px 0px #bcbcbc;}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {background: #fff;}

.ui-state-hover:after,
.ui-widget-content .ui-state-hover:after,
.ui-widget-header .ui-state-hover:after,
.ui-button:hover:after {content: ""; position: absolute; top: 5px; left: 5px; width: 20px; height: 20px; border-radius: 10px; border: 1px solid #00355f;}

.ui-state-hover:first-of-type:after,
.ui-widget-content .ui-state-hover:first-of-type:after,
.ui-widget-header .ui-state-hover:first-of-type:after,
.ui-button:hover:first-of-type:after {border: 1px solid #009a8c;}

#slider-range, #slider-range2 {margin: 30px 5px 0 5px; padding: 0 5px;}
.range-ruler {margin: 22px -5px 17px -5px;}
.range-ruler div {position: relative; width: 23px; font-size: 10px; text-align: center; color: #b8b8b8;}
.range-ruler div:before {content: ""; position: absolute; top: -11px; left: 11px; width: 1px; height: 10px; background-color: #b8b8b8;}

.amount-container {display: inline-block; height: 36px; margin: 0px 30px 0px 0;}
.amount-container:last-of-type {margin-right: 0px;}
.amount-container label, .amount-container span {font-size: 16px; color: #909293;}
.amount-input-container {display: inline-block; width: 120px; height: 36px; margin: 0 0 0 10px; padding: 5px 18px; border-radius: 18px; background-color: #fff;}
@media (max-width: 1920.98px) {
.amount-container label, .amount-container span {font-size: 15px;}
}
@media (max-width: 1499.98px) {
.amount-container label, .amount-container span {font-size: 14px;}
}



#amount, #amount2, #amount3, #amount4 {width: 50px;}

@media (max-width: 1399.98px) {
.amount-input-container {width: 100px; padding: 5px 8px 5px 13px;}
#amount, #amount2, #amount3, #amount4 {width: 45px;}
}
@media (max-width: 449.98px) {
.amount-container {height: 32px; margin: 0px 15px 0 0;}
.amount-input-container {width: 90px; height: 32px; padding: 3px 8px 3px 13px; margin: 0 0 0 5px; border-radius: 16px;}
.amount-container label, .amount-container span {font-size: 13px;}
#amount, #amount2, #amount3, #amount4 {width: 35px; font-size: 14px;}
}
@media (max-width: 374.98px) {
.amount-input-container {width: 80px; padding: 3px 5px 3px 10px;}
}
@media (max-width: 349.98px) {
.amount-container {margin: 0px 10px 0 0;}
.amount-container label, .amount-container span {font-size: 12px;}
#amount, #amount2, #amount3, #amount4 {width: 32px; font-size: 13px;}
.amount-input-container {width: 75px;}
}


/* search */

.search {position: relative; width: 307px; border-radius: 18px; margin-left: 30px; box-shadow: 5px 5px 20px 0px #dddddd;}
.search input {position: relative; z-index: 5; display: inline-block; width: 260px; height: 36px; margin: 0; padding: 5px 30px 5px 25px; border-radius: 18px; border: none !important; font-size: 14px; color: #909293; background: #f8f8f8;}
.search input:focus {outline: none;}
.search .search-button {position: absolute; top: 0px; right: 0px; display: inline-block; width: 75px; height: 36px; border-radius: 18px; background-size: 200% 100%; background-image: linear-gradient(to right, #00355f 50%, #006c78 50%); transition: all .4s;}
.search:hover .search-button {background-position: -100% 0;}
.search .search-button button {position: relative; top: 0px; left: 0px; width: 75px; height: 36px; padding: 0 0 0 22px; margin: 0; border: 0; border-radius: 18px;}
.search .search-button button:focus, .search .search-button button:active {background-color: #004867;}
@media (max-width: 767.98px) {
.search {width: 247px;}
.search input {width: 200px;}
}
@media (max-width: 499.98px) {
.search {margin-left: 25px; margin-bottom: 10px;}
}




.col-right table {width: 100%; border-radius: 11px; overflow: hidden;}
.col-right .table-robots-list {margin-bottom: 20px; border-radius: 11px;}
.col-right .table-selected-robots {margin-bottom: 20px;}
.col-right tr {background: #ececec; transition: all 0.6s;}
.col-right tr:nth-child(even) {background: #e6e6e6;}
.col-right tr:hover {background: #f9f9f9;}
.col-right th {padding: 17px 25px; font-size: 16px; font-weight: 500; text-transform: uppercase; text-align: center; color: #000; border-right: 1px solid #fcfcfc; background: #ececec;}
.col-right .table-selected-robots th:first-of-type {padding: 17px 50px; text-align: left;}
.col-right td {padding: 17px 25px; font-size: 16px; white-space: nowrap; text-align: center; border-right: 1px solid #fcfcfc; color: #868889; transition: all 0.6s;}
.col-right .table-selected-robots td:first-of-type {padding: 17px 30px 17px 50px; text-align: left;}
.col-right tr:hover td {color: #000;}
.col-right th:last-child, .col-right td:last-child {border-right: none;}
.col-right .checkbox-label {top: -6px; padding-left: 25px;}
.col-right td img.robot-type-img {height: 90px; width: auto;}
.large-inertia-mode .lim-short {display: none; position: relative;}
.large-inertia-mode .lim-short span {cursor: pointer; position: relative; top: 4px; display: inline-block; width: 17px; height: 17px; margin-left: 5px; padding-left: 6px; font-size: 13px; font-weight: 500; border-radius: 10px; background-color: #868889; color: #fff; transition: all 0.6s;}
.large-inertia-mode .lim-short span:after {content: "?"; position: absolute; top: -1px;}
.col-right tr:hover .large-inertia-mode .lim-short span {background-color: #000;} 
.col-right .table-robots-list {overflow: auto; padding-right: 20px;}
.col-right .table-robots-list {scrollbar-color: #fff transparent; scrollbar-width: auto;}
.col-right .table-robots-list::-webkit-scrollbar {border-radius: 12px; width: 24px; height: 24px; background-color: #fff;}
.col-right .table-robots-list::-webkit-scrollbar-track {border-radius: 12px; background-color: #fff;}
.col-right .table-robots-list::-webkit-scrollbar-thumb {border-radius: 12px; background-color: #e6e6e6;}
@media (min-width: 1200px) {
.col-right .table-robots-list {height: calc(100vh - 350px);}
}
@media (min-width: 1500px) {
.col-right .table-robots-list {height: calc(100vh - 330px);}
}
@media (max-width: 1920.98px) {
.col-right th, .col-right td {padding: 17px 20px; font-size: 15px;}
}
@media (max-width: 1799.98px) {
.large-inertia-mode .lim-long {display: none;}
.large-inertia-mode .lim-short {display: inline-block; top: -1px;}
}
@media (max-width: 1699.98px) {
.col-right th, .col-right td {font-size: 14px;}
.col-right th span {display: none;}
.col-right td img.robot-type-img {height: 70px;}
.col-right .table-selected-robots th:first-of-type {padding: 17px 50px 17px 40px;}
.col-right .table-selected-robots td:first-of-type {padding: 17px 30px 17px 40px;}
}
@media (max-width: 1599.98px) {
.col-right th, .col-right td {padding: 17px 18px;}
}
@media (max-width: 1499.98px) {
.col-right th, .col-right td {font-size: 13px;}
}
@media (max-width: 1349.98px) {
.col-right th, .col-right td {padding: 17px 14px;}
.col-right td img.robot-type-img {height: 50px;}
.col-right .table-selected-robots th:first-of-type {padding: 17px 50px 17px 35px;}
.col-right .table-selected-robots td:first-of-type {padding: 17px 30px 17px 35px;}
}
@media (max-width: 1199.98px) {
.large-inertia-mode .lim-long {display: inline-block;}
.large-inertia-mode .lim-short {display: none;}
.col-right .table-robots-list {max-height: 100% !important;}
.col-right td img.robot-type-img {height: 70px;}
}
@media (max-width: 991.98px) {
.col-right .table-container {overflow-x: auto; margin-bottom: 30px;}
.col-right .table-container::-webkit-scrollbar {width: 24px;}
.col-right .table-container::-webkit-scrollbar-track {border-radius: 12px; background-color: #fff;}
.col-right .table-container::-webkit-scrollbar-thumb {border-radius: 12px; background-color: #e6e6e6;}
.col-right table {width: 100%; margin-bottom: 10px;}
}


.bom-button {position: relative; display: inline-block; width: 88px; height: 28px; padding: 0; border-radius: 14px; background: #fff; box-shadow: 5px 5px 20px 0px #bfbfbf; transition: all .3s;}
.bom-button .bom-button-inner {position: relative; left: 0px; display: block; width: 65px; height: 28px; padding: 4px 15px; border-radius: 14px; font-size: 15px; text-align: left; font-weight: 500; color: #fff; background-image: linear-gradient(to top, #006c78 0%, #004767 100%); transition: all .3s;}
.bom-button:hover .bom-button-inner {width: 88px;}
.bom-button .bom-button-img {position: absolute; z-index: 3; top: 7px; right: 10px; width: 8px; height: 13px;}
.bom-button .bom-button-img img {position: absolute; top: 0; left: 0; transition: opacity .3s;}
.bom-button .bom-button-img img:nth-child(1) {opacity: 0;}
.bom-button .bom-button-img img:nth-child(2) {opacity: 1;}
.bom-button:hover .bom-button-img img:nth-child(1) {opacity: 1;}
.bom-button:hover .bom-button-img img:nth-child(2) {opacity: 0;}
.bom-button:active .bom-button-inner, .bom-button:focus .bom-button-inner {background-image: linear-gradient(to bottom, #006c78 0%, #004767 100%);}
@media (max-width: 1920.98px) {
.bom-button {width: 83px; height: 28px;}
.bom-button .bom-button-inner {width: 60px; height: 28px; font-size: 13px;}
.bom-button:hover .bom-button-inner {width: 83px;}
}
@media (max-width: 1599.98px) {
.bom-button {width: 70px; height: 24px; border-radius: 12px;}
.bom-button .bom-button-inner {width: 50px; height: 24px; padding: 3px 11px; font-size: 12px; border-radius: 12px;}
.bom-button:hover .bom-button-inner {width: 70px;}
.bom-button .bom-button-img {position: absolute; z-index: 3; top: 5px; right: 8px;}
}


.modal-bom .modal-body {margin-left: -36px; padding-bottom: 0;}
.modal-bom .table-container {overflow: auto; width: 100%; height: 400px; padding-right: 15px;}
.modal-bom table {width: 100%; border-radius: 11px; overflow: hidden;}
.modal-bom tr {border-bottom: 1px solid #fcfcfc; background: #ececec; transition: all 0.6s;}
.modal-bom tr:hover {background: #f9f9f9;}
.modal-bom th {padding: 17px 35px; font-size: 16px; font-weight: 500; text-transform: uppercase; white-space: nowrap; color: #000; border-right: 1px solid #fcfcfc; background: #ececec;}
.modal-bom td {padding: 17px 35px; font-size: 16px; white-space: nowrap; border-right: 1px solid #fcfcfc; color: #868889; transition: all 0.6s;}
.modal-bom td.bom-desc {white-space: normal;}
.modal-bom tr:hover td {color: #000;}
.modal-bom th:last-child, .modal-bom td:last-child {border-right: none;}
.modal-bom .bom-button {width: 100px;}
.modal-bom .bom-button .bom-button-inner {width: 77px;}
.modal-bom .bom-button:hover .bom-button-inner {width: 100px;}
@media (max-width: 1920.98px) {
.modal-bom .table-container {overflow: auto; height: 350px;}
.modal-bom th, .modal-bom td {font-size: 15px;}
.modal-bom .bom-button {width: 95px;}
.modal-bom .bom-button .bom-button-inner {width: 72px;}
.modal-bom .bom-button:hover .bom-button-inner {width: 95px;}
}
@media (max-width: 1699.98px) {
.modal-bom th, .modal-bom td {font-size: 14px;}
}
@media (max-width: 1599.98px) {
.modal-bom .bom-button {width: 78px;}
.modal-bom .bom-button .bom-button-inner {width: 58px;}
.modal-bom .bom-button:hover .bom-button-inner {width: 78px;}
}
@media (max-width: 1499.98px) {
.modal-bom th, .modal-bom td {font-size: 13px;}
}
@media (min-width: 992px) {
.modal-bom .table-container::-webkit-scrollbar {width: 24px;}
.modal-bom .table-container::-webkit-scrollbar-track {border-radius: 12px; background-color: #fff;}
.modal-bom .table-container::-webkit-scrollbar-thumb {border-radius: 12px; background-color: #e6e6e6;}
}
@media (max-width: 991.98px) {
.modal-bom .table-container {height: auto;}
.modal-bom table {width: 900px;}
}
@media (max-width: 576.98px) {
.modal-bom .modal-body {margin-right: -36px;}
.modal-bom h3 {width: calc(100% - 30px);}
}

.modal-bom .dropdown {margin-left: 35px;}
.modal-bom .dropdown-button {display: inline-block; position: relative; width: 200px; padding: 7px 25px; font-size: 16px; font-weight: 500; text-decoration: none; color: #23586d; border-radius: 19px; background-color: #fff; box-shadow: 0px 0px 10px 0px #d2d2d2;}
.modal-bom .dropdown-button:hover {text-decoration: none; color: #23586d;}
.modal-bom .dropdown .dropdown-button:after {content:""; position: absolute; top: 12px; right: 18px; width: 8px; height: 13px; background-image: url(../../img/arrow-right.png); transition: all 0.4s;}
.modal-bom .dropdown.show .dropdown-button:after {transform: rotate(90deg);}
.modal-bom .dropdown-menu {min-width: 200px; padding: 15px 0; margin: 0; border-radius: 19px; background-color: #fff; border: none; box-shadow: 0px 0px 10px 0px #d2d2d2; transition: all 0.4s;}
.modal-bom .dropdown-item {margin: 0; padding: 3px 25px; font-size: 16px; font-weight: 500; color: #868889;}
.modal-bom .dropdown-item:hover {color: #23586d; background: #fff;}
@media (max-width: 1920.98px) {
.modal-bom .dropdown-button, .modal-bom .dropdown-item {font-size: 15px;}
.modal-bom .dropdown-button {padding: 8px 25px;}
}
@media (max-width: 1499.98px) {
.modal-bom .dropdown-button, .modal-bom .dropdown-item {font-size: 14px;}
.modal-bom .dropdown-button {padding: 9px 25px;}
}
@media (max-width: 1399.98px) {
.modal-bom .dropdown-button, .modal-bom .dropdown-item {font-size: 13px;}
.modal-bom .dropdown-button {padding: 10px 25px;}
}
@media (max-width: 576.98px) {
.modal-bom .dropdown {margin-left: 0; margin-top: 25px;}
}



.selected-robot-bg1 {background-color: #4595e0;}
.selected-robot-bg2 {background-color: #53b2aa;}
.selected-robot-bg3 {background-color: #1b4874;}

.selected-robot-checkbox {position: relative; width: 25px; height: 25px; margin: 0 40px 0 0; border-radius: 7px;}
.selected-robot-checkbox.active:after {content: url('../../img/star.png'); position: absolute; top: 1px; left: 6px;}
.selected-robot-name {line-height: 25px; padding-right: 20px;}
@media (max-width: 1920.98px) {
.selected-robot-checkbox {margin: 0 30px 0 0;}
.selected-robot-checkbox.active:after {top: 3px;}
}
@media (max-width: 1699.98px) {
.selected-robot-checkbox {margin: 0 25px 0 0;}
.selected-robot-checkbox.active:after {top: 4px;}
}
@media (max-width: 1499.98px) {
.selected-robot-checkbox.active:after {top: 5px;}
}

.selected-robot-result {position: relative; display: inline-block; width: 25px; height: 25px; margin-top: 4px; border-radius: 50%; box-shadow: 5px 5px 20px 0px #dcdcdc;}
.selected-robot-result-success {position: absolute; left: 9px; top: 5px; width: 7px; height: 12px; border: solid white; border-width: 0 2px 2px 0; transform: rotate(45deg);}
.selected-robot-result-error .bar-1, .selected-robot-result-error .bar-2 {width: 14px; height: 2px; margin: 7px 0 0 3px; background-color: #fff;}
.selected-robot-result-error .bar-1 {transform: rotate(-45deg); transform-origin: 100% 0%;}
.selected-robot-result-error .bar-2 {transform: rotate(45deg); transform-origin: 100% 100%;}


.table-selected-robots .close-button {margin-top: 4px;}



.tooltip.show {opacity: 1;}
.tooltip-inner {padding: 4px 12px; border-radius: 10px; color: #868889; background-color: #fff; box-shadow: 5px 5px 20px 0px #e8e8e8;}
.tooltip-inner img {height: 90px; width: auto; margin: 16px 8px;}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {border-top-color: #fdfdfd;}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {height: 26px; box-shadow: 5px 5px 20px 0px #e8e8e8;}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {border-width: 13px 13px 13px 0; border-right-color: #fdfdfd; }
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {border-bottom-color: #fdfdfd;}



.success-color {color: #3d8b64;}
.success-color-bg {background-color: #3d8b64;}
.error-color {color: #be2e33;}
.error-color-bg {background-color: #be2e33;}


.payload-header h3 {line-height: 1;}
.payload-header .selected-robots {position: relative; top: -2px;}
.payload-header .selected-robot {position: relative; display: inline-block; padding: 0 20px 0 30px; font-size: 16px; color: #868889;}
.payload-header .selected-robot:before {content: ""; position: absolute; left: 0px; top: 2px; width: 20px; height: 20px; border-radius: 4px; background: #000;}
.payload-header .selected-robot-1:before {background-color: #4595e0;}
.payload-header .selected-robot-2:before {background-color: #53b2aa;}
.payload-header .selected-robot-3:before {background-color: #1b4874;}
.payload-header .center-of-mass {position: relative; margin-left: 150px; font-size: 14px; color: #868889;}
.payload-header .center-of-mass .center-of-mass-icon {position: relative; top: 2px; display: inline-block; width: 24px; height: 24px; border: 2px solid #d2152b; border-radius: 50%; background-color: #fff;}
.payload-header .center-of-mass .center-of-mass-icon:after {content: ""; position: absolute; top: 3px; left: 3px; width: 14px; height: 14px; border-radius: 50%; background-color: #d2152b;}
.payload-header .center-of-mass .center-of-mass-label {position: relative; top: -5px; display: inline-block; padding: 0 0 0 10px;}
@media (max-width: 1920.98px) {
.payload-header .selected-robot {font-size: 15px;}
.payload-header .selected-robot:before {top: 1px;}
.payload-header .center-of-mass {font-size: 15px;}
}
@media (max-width: 1699.98px) {
.payload-header .center-of-mass {margin-left: 100px;}
}
@media (max-width: 1499.98px) {
.payload-header .selected-robot {font-size: 14px;}
.payload-header .selected-robot:before {top: 0px;}
.payload-header .center-of-mass {font-size: 14px;}
}
@media (max-width: 1499.98px) {
}
@media (max-width: 1199.98px) {
.payload-header .selected-robot {font-size: 13px;}
.payload-header .selected-robot:before {top: -1px;}
.payload-header .center-of-mass {margin-left: 50px; font-size: 13px;}
.payload-header .center-of-mass .center-of-mass-label {top: -5px;}
}
@media (max-width: 767.98px) {
.payload-header h3 {width: 100%; margin-bottom: 20px;}
.payload-header .center-of-mass {margin-left: auto;}
}
@media (max-width: 599.98px) {
.payload-header .selected-robot {margin: 0 0 10px 2px;}
.payload-header .center-of-mass {width: 100%; margin-left: 0;}
}


.load-switch {margin-left: auto; box-shadow: 5px 5px 20px 0px #e8e8e8;}
.load-switch-button {display: inline-block; padding: 11px 40px; font-size: 12px; line-height: 13px; font-weight: 500; text-transform: uppercase; text-decoration: none; white-space: nowrap; color: #00355b; border: 1px solid #00355b; background-size: 100% 200%; background-image: linear-gradient(to bottom, #fff 0%, #f9f9f9 50%, #00355b 50%, #00355b 100%); transition: all .3s;}
.load-switch-button:nth-child(1) {border-top-left-radius: 18px; border-bottom-left-radius: 18px;}
.load-switch-button:nth-child(2) {border-top-right-radius: 18px; border-bottom-right-radius: 18px;}
.load-switch-button.active, .load-switch-button:hover {text-decoration: none; color: #fff; background-position: 0 100%;}
@media (max-width: 1499.98px) {
.load-switch-button {padding: 11px 35px;}
}
@media (max-width: 575.98px) {
.load-switch {margin: 20px 0 0 0;}
}
@media (max-width: 339.98px) {
.load-switch-button {padding: 11px 25px;}
}


.grid-payload-container, .grid-load-container {position: relative; margin: 35px 55px 0 0;}
@media (max-width: 1199.98px) {
.grid-payload-container, .grid-load-container {position: relative; margin: 35px 67px 0 0;}
}
@media (max-width: 849.98px) {
.grid-outer-container {overflow-x: auto; margin-bottom: 15px;}
.grid-outer-container::-webkit-scrollbar {width: 24px;}
.grid-outer-container::-webkit-scrollbar-track {border-radius: 12px; background-color: #e6e6e6;}
.grid-outer-container::-webkit-scrollbar-thumb {border-radius: 12px; background-color: #fff;}
.grid-payload-container, .grid-load-container {width: 850px; margin-bottom: 15px;}
}


.grid {width: calc(100% - 66px); height: 160px; margin: 0; border: solid #c9c9c9; border-width: 0 0 1px 1px}
.grid-payload .grid-element {width: 25%; height: 16px; border: dashed #c9c9c9; border-width: 1px 1px 0 0;}
.grid-load .grid-element {width: 16.666667%; height: 16px; border: dashed #c9c9c9; border-width: 1px 1px 0 0;}

.grid-left-label {width: 30px; transform: rotate(180deg); writing-mode: vertical-rl; font-size: 16px; text-align: center; color: #868889;}
.grid-bottom-label {width: 100%; margin: 10px 0 0 66px; font-size: 16px; text-align: center; color: #868889;}
@media (max-width: 1920.98px) {
.grid-left-label, .grid-bottom-label {font-size: 15px;}
}
@media (max-width: 1499.98px) {
.grid-left-label, .grid-bottom-label {font-size: 14px;}
}

.grid-left {position: relative; width: 36px; height: 160px; margin: 0; font-size: 10px; line-height: 1; color: #868889;}
.grid-left .grid-100 {position: absolute; right: 14px; top: -4px;}
.grid-left .grid-80 {position: absolute; right: 14px; top: 28px;}
.grid-left .grid-60 {position: absolute; right: 14px; top: 60px;}
.grid-left .grid-40 {position: absolute; right: 14px; top: 92px;}
.grid-left .grid-20 {position: absolute; right: 14px; top: 124px;}
.grid-left .grid-0 {position: absolute; right: 14px; top: 155px;}
.grid-left div:after {content: ""; position: absolute; top: 4px; right: -14px; width: 10px; height: 1px; background-color: #c9c9c9;}


.grid-bottom {position: relative; width: 100%; height: 30px; margin: 0 0 0 66px; font-size: 10px; line-height: 1; color: #868889;}
.grid-payload-container .grid-bottom .grid-0 {position: absolute; top: 14px; left: -3px; width: 7px;}
.grid-payload-container .grid-bottom .grid-40 {position: absolute; top: 14px; left: calc(25% - 5px); width: 11px;}
.grid-payload-container .grid-bottom .grid-80 {position: absolute; top: 14px; left: calc(50% - 6px); width: 12px;}
.grid-payload-container .grid-bottom .grid-120 {position: absolute; top: 14px; left: calc(75% - 9px); width: 18px;}
.grid-payload-container .grid-bottom .grid-160 {position: absolute; top: 14px; left: calc(100% - 10px); width: 19px;}
.grid-payload-container .grid-bottom div:after {content: ""; position: absolute; top: -14px; left: 50%; transform: translateX(-50%); width: 1px; height: 10px; background-color: #c9c9c9;}
.grid-load-container .grid-bottom div {position: relative; width: 16.666667%; height: 30px; padding: 10px; text-align: center;}
.grid-load-container .grid-bottom div:after {content: ""; position: absolute; top: 0; right: 0; width: 1px; height: 10px; background-color: #c9c9c9;}
.grid-load-container .grid-bottom div:first-child:before {content: ""; position: absolute; top: 0; left: 0; width: 1px; height: 10px; background-color: #c9c9c9;}


.payload-objects {position: absolute; top: 0; left: 66px; width: calc(100% - 66px); height: 160px;}
.payload-objects .center-of-mass {position: absolute; top: 84px; left: 20%; width: 24px; height: 24px; border: 2px solid #d2152b; border-radius: 50%; background-color: #fff;}
.payload-objects .center-of-mass:after {content: ""; position: absolute; top: 3px; left: 3px; width: 14px; height: 14px; border-radius: 50%; background-color: #d2152b;}
.payload-objects .payload-line:nth-child(2) {position: absolute; top: 16px; width: 100%; height: 4px;}
.payload-objects .payload-line:nth-child(3) {position: absolute; top: 40px; width: 100%; height: 4px;}
.payload-objects .payload-line:nth-child(4) {position: absolute; top: 70px; width: 100%; height: 4px;}
.payload-objects .payload-line-indicator {position: absolute; top: 33px; left: 40%; width: 18px; height: 18px; border: 6px solid #fff; border-radius: 50%;}
.payload-objects .bars-container {width: 16.666667%; height: 159px; margin: 0; padding: 0;}
.payload-objects .bar {width: 20px; margin: 0 5px; border-top-left-radius: 5px; border-top-right-radius: 5px;}
.payload-objects .bar:nth-child(1) {background-color: #4595e0; background-image: linear-gradient(to top, #2196e6 0%, #1e84ca 100%);}
.payload-objects .bar:nth-child(2) {background-color: #53b2aa; background-image: linear-gradient(to top, #17b5aa 0%, #149c92 100%);}
.payload-objects .bar:nth-child(3) {background-color: #1b4874; background-image: linear-gradient(to top, #044878 0%, #043a60 100%);}
.payload-objects .overloaded .bar:nth-child(1) {background-image: linear-gradient(to top, #2196e6 0%, #1e84ca 70%, #d2152b 100%);}
.payload-objects .overloaded .bar:nth-child(2) {background-image: linear-gradient(to top, #17b5aa 0%, #149c92 70%, #d2152b 100%);}
.payload-objects .overloaded .bar:nth-child(3) {background-image: linear-gradient(to top, #044878 0%, #043a60 70%, #d2152b 100%);}
@media (max-width: 1599.98px) {
.payload-objects .bar {width: 18px;}
}
@media (max-width: 1399.98px) {
.payload-objects .bar {width: 16px; margin: 0 4px; border-top-left-radius: 4px; border-top-right-radius: 4px;}
}
@media (max-width: 1199.98px) {
.payload-objects .bar {width: 20px; margin: 0 5px; border-top-left-radius: 5px; border-top-right-radius: 5px;}
}
@media (max-width: 991.98px) {
.payload-objects .bar {width: 16px; margin: 0 4px; border-top-left-radius: 4px; border-top-right-radius: 4px;}
}


.grid-load-inner-container.w50 {position: relative; width: calc(50% - 40px);}
.grid-load-inner-container.w50.w50-1 {margin-right: 25px;}
.grid-load-inner-container.w50.w50-2 {margin-left: 25px;}
.grid-load-inner-container.w50 .grid {width: calc(100% - 33px); height: auto; margin: 0 0 0 33px; border: solid #c9c9c9; border-width: 0 0 1px 1px}
.grid-load-inner-container.w50 .grid-load .grid-element {width: 20%; height: 100px; border: dashed #c9c9c9; border-width: 1px 1px 0 0;}

.grid-load-inner-container.w50 .grid-left {position: relative; width: 36px; height: auto; margin: 0; font-size: 10px; line-height: 1; color: #868889;}
.grid-load-inner-container.w50 .grid-left .grid-a1 {position: absolute; right: 14px; top: 48px;}
.grid-load-inner-container.w50 .grid-left .grid-a2 {position: absolute; right: 14px; top: 148px;}
.grid-load-inner-container.w50 .grid-left .grid-a3 {position: absolute; right: 14px; top: 248px;}
.grid-load-inner-container.w50 .grid-left div:after {content: ""; position: absolute; top: -48px; right: -14px; width: 10px; height: 1px; background-color: #c9c9c9;}

.grid-load-inner-container.w50 .grid-bottom {position: relative; width: 100%; height: 30px; margin: 0 0 0 33px; font-size: 10px; line-height: 1; color: #868889;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom .grid-0 {position: absolute; top: 14px; left: -3px; width: 7px;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom .grid-20 {position: absolute; top: 14px; left: calc(20% - 5px); width: 11px;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom .grid-40 {position: absolute; top: 14px; left: calc(40% - 6px); width: 12px;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom .grid-60 {position: absolute; top: 14px; left: calc(60% - 6px); width: 12px;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom .grid-80 {position: absolute; top: 14px; left: calc(80% - 6px); width: 12px;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom .grid-100 {position: absolute; top: 14px; left: calc(100% - 10px); width: 18px;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom div:after {content: ""; position: absolute; top: -14px; left: 50%; translateX: -50%; width: 1px; height: 10px; background-color: #c9c9c9;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom div {position: relative; width: 20%; height: 30px; padding: 0px; text-align: center;}
.grid-load-container .grid-load-inner-container.w50 .grid-bottom div:first-child:before {display: none;}

.grid-load-inner-container.w50 .payload-objects  {position: absolute; top: 0; left: 33px; width: calc(50% - 33px); height: auto;}
.grid-load-inner-container.w50 .payload-objects .bars-container {width: 33.333334%; height: 100px; margin: 0; padding: 0;}
.grid-load-inner-container.w50 .payload-objects .bar {height: 20px; margin: 5px 0; border-top-left-radius: 0px;  border-top-right-radius: 5px; border-bottom-right-radius: 5px;}
.grid-load-inner-container.w50 .payload-objects .bar:nth-child(1) {background-color: #4595e0; background-image: linear-gradient(to right, #2196e6 0%, #1e84ca 100%);}
.grid-load-inner-container.w50 .payload-objects .bar:nth-child(2) {background-color: #53b2aa; background-image: linear-gradient(to right, #17b5aa 0%, #149c92 100%);}
.grid-load-inner-container.w50 .payload-objects .bar:nth-child(3) {background-color: #1b4874; background-image: linear-gradient(to right, #044878 0%, #043a60 100%);}
.grid-load-inner-container.w50 .payload-objects .overloaded .bar:nth-child(1) {background-image: linear-gradient(to right, #2196e6 0%, #1e84ca 70%, #d2152b 100%);}
.grid-load-inner-container.w50 .payload-objects .overloaded .bar:nth-child(2) {background-image: linear-gradient(to right, #17b5aa 0%, #149c92 70%, #d2152b 100%);}
.grid-load-inner-container.w50 .payload-objects .overloaded .bar:nth-child(3) {background-image: linear-gradient(to right, #044878 0%, #043a60 70%, #d2152b 100%);}




.bottom-bar {width: 100%; min-height: 40px; padding: 0 50px 20px 50px; font-size: 10px; color: #818181;}
.bottom-bar a {color: #818181;}
.bottom-bar .newman a {text-decoration: none;}
.bottom-bar .newman a img {margin-left: 5px;}
@media (max-width: 1199.98px) {
.bottom-bar {padding: 20px 50px 20px 50px;}
}
@media (max-width: 767.98px) {
#intro .bottom-bar {padding: 20px calc(10vw + 5px);}
}
@media (max-width: 575.98px) {
.bottom-bar {padding: 20px 30px 20px 30px;}
.bottom-bar .newman {margin-top: 10px;}
}
@media (max-width: 359.98px) {
.bottom-bar {padding: 20px 25px 20px 25px;}
}
