@import "variables";

.selected-robot-result {
    &.error-color-bg {
        .selected-robot-result-success {
            display: none;
        }
    }

    &.success-color-bg {
        .selected-robot-result-error {
            display: none;
        }
    }
}

.payload-header .center-of-mass {
    .center-of-mass-label {
        top: 0 !important;
    }

    .center-of-mass-image {
        top: -1px;
        position: relative;
    }
}

.load-canvas-container {
    width: 100%;
}

.grid-load-inner-container.w100 {
    position: relative;
    width: calc(100% - 40px);
}

.grid-payload-container > .chart-payload-container-vertical {
    width: 100%;
}

.grid-payload-container > .chart-payload-container-horizontal {
    height: 0;
    width: 100%;
    padding-bottom: 100%;
}

@media (min-width: 1700px) {
    .grid-payload-container > .chart-payload-container-vertical {
        width: 75%;
        margin: 0 auto;
    }

    .grid-payload-container > .chart-payload-container-horizontal {
        height: 0;
        width: 75%;
        padding-bottom: 75%;
        margin: 0 auto;
    }
}

.add-payloads-container > section > div {
    padding: 18px 40px;
}

@media (max-width: 1399.98px) {
    .add-payloads-container > section > div {
        padding: 18px 25px;
    }
}